// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Suppliers-module--3OqZs {\n    display: grid;\n    align-content: start;\n    grid-template-columns: repeat(auto-fill, minmax(110px, 1fr));\n    gap: 16px;\n\n    width: 1090px;\n    max-width: 100%;\n    margin: 0 auto;\n    min-height: 353px;\n}\n\n@media (max-width: 1140px) {\n    .Suppliers-module--3OqZs {\n        width: 100%;\n        padding: 0 20px;\n    }\n}\n", "",{"version":3,"sources":["webpack://./src/components/Suppliers/Suppliers.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,oBAAoB;IACpB,4DAA4D;IAC5D,SAAS;;IAET,aAAa;IACb,eAAe;IACf,cAAc;IACd,iBAAiB;AACrB;;AAEA;IACI;QACI,WAAW;QACX,eAAe;IACnB;AACJ","sourcesContent":[".container {\n    display: grid;\n    align-content: start;\n    grid-template-columns: repeat(auto-fill, minmax(110px, 1fr));\n    gap: 16px;\n\n    width: 1090px;\n    max-width: 100%;\n    margin: 0 auto;\n    min-height: 353px;\n}\n\n@media (max-width: 1140px) {\n    .container {\n        width: 100%;\n        padding: 0 20px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Suppliers-module--3OqZs"
};
export default ___CSS_LOADER_EXPORT___;
